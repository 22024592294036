.root {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

* {
  padding: 0;
  margin: 0;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-between-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-start-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-start-start-reverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-start-start-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.fill {
  width: 100%;
  height: 100%;
}
